
import {Loading} from "element-ui"
import {Component, Vue, Watch} from 'vue-property-decorator'
import {apiShopDetail, apiShopAdd, apiShopEdit, apiSetMealLists, apiCheckScapegoatsDomains} from '@/api/shop'
import historyMeal from './components/history-meal.vue'
import {debounce, clog, clogJsons, timeFormat} from '@/utils/util'
import lsPagination from '@/components/ls-pagination.vue'
import ExportData from '@/components/export-data/index.vue'
import LsDialog from '@/components/ls-dialog.vue'
import {ElLoadingComponent, LoadingServiceOptions} from "element-ui/types/loading";

@Component({
  components: {
    historyMeal
  }
})
export default class ListsDetail extends Vue {
  /** S Data **/

  identity: number | null = null; // 当前编辑用户的身份ID

  setMealData: any = []

  loadingOption: LoadingServiceOptions = {
    target: document.querySelector('body') || undefined,
    body: true,
    fullscreen: true,
    lock: true
  }

  loadInstance: ElLoadingComponent | undefined

  @Watch('form.storage_enable')
  onFTPStoreEngine(nval: any, oval: any) {
    if (nval == 2) {
      this.isProxy = true;
    } else {
      this.isProxy = false;
    }
  }

  isProxy = false

  // 添加商城表单数据
  form: any = {
    name: '', // 名称
    super_admin: '', // 账号
    super_password: '', // 密码
    super_password_confirm: '', // 确认密码
    status: 1, // 商城状态 0-停止服务 1-开启服务
    contact: '', // 联系人
    contact_mobile: '', // 联系人手机号
    domain_alias: '', // 域名别名
    is_check_domain: 0, // 是否验证域名
    shop_url: '', // 当前域名
    set_meal_id: '', // 套餐ID
    expires_time: '', // 到期时间
    remark: '', // 备注
    shop_data: {},
    storage_enable: 1,
    proxy_domain: '',

    // 炮灰域名 - 备用域名
    scapegoats: {
      infos: []
    },

    // 炮灰域名 - 备用域名
    scapegoatsSubmit: [
      // {
      //   id: 0,
      //   domain: ''
      // }
    ]
  };

  // 表单校验
  rules = {
    name: [{required: true, message: '请输入商城名称', trigger: 'blur'}],
    super_admin: [
      {required: true, message: '请输入商城名称', trigger: 'blur'}
    ],
    super_password: [
      {required: true, message: '请输入密码', trigger: 'blur'},
      {
        validator: (rule: object, value: string, callback: any) => {
          !value ? callback(new Error('请输入密码')) : callback()
        },
        trigger: 'blur'
      }
    ],
    super_password_confirm: [
      {required: true, message: '请再次输入密码', trigger: 'blur'},
      {
        validator: (rule: object, value: string, callback: any) => {
          if (this.form.password) {
            if (!value) callback(new Error('请再次输入密码'))
            if (value !== this.form.password) {
              callback(new Error('两次输入密码不一致!'))
            }
          }
          callback()
        },
        trigger: 'blur'
      }
    ],
    set_meal_id: [{required: true, message: '请选择套餐', trigger: ['blur', 'change']}],
    expires_time: [{required: true, message: '请选择到期时间', trigger: ['blur', 'change']}]
  };

  /** E Data **/

  /** S Methods **/

  // 点击删除炮灰域名
  clickDeleteScapegoats (index: number) {
    this.form.scapegoats.infos.splice(index, 1)
  }

  // 点击添加炮灰域名
  clickAddScapegoats () {
    this.form.scapegoats.infos.push({
      domain: '',
      id: 0
    })
  }

  // 炮灰域名输入后取出外侧空格 + 取出协议头 + 去除重复
  onBlurInputScapegoats (index: number) {
    let curVal = this.form.scapegoats.infos[index].domain
    // 空格
    let tobe = curVal.replace(/^\s+|\s+$/g, '')
    // 协议头
    tobe = tobe.replace(/(http:\/\/|https:\/\/)/g, '')
    // 写入
    this.form.scapegoats.infos[index].domain = tobe
    

    // 去除重复
    if (tobe !== '' && this.form.scapegoats.infos.filter((item: any) => item.domain === tobe).length > 1) {
      // 不止一条被过滤掉
      this.form.scapegoats.infos[index].domain = ''
      this.$message.error('域名重复 ' + tobe)
    }
  }

  // 点击表单提交
  onSubmit(formName: string) {
    clog('点击表单提交')
    // 验证表单格式是否正确
    const refs = this.$refs[formName] as HTMLFormElement
    refs.validate((valid: boolean): any => {
      clog('是否有效', valid)
      if (!valid) {
        return
      } else {
        // 炮灰域名检查后再提交
        this.checkScapegoatsDomainsAndSubmit()
      }
    })
  }


  // 炮灰域名检查后再提交
  async checkScapegoatsDomainsAndSubmit() {
    clog('炮灰域名检查')
    this.loadInstance = Loading.service(this.loadingOption)

    // 域名规范
    let list = JSON.parse(JSON.stringify(this.form.scapegoats.infos)).filter((item: any) => item.domain !== '')
    if (list.length != this.form.scapegoats.infos) {
      this.form.scapegoats.infos = list
    }
    clogJsons(this.form.scapegoats.infos)

    if (list.length) {
      // 检查炮灰域名是否可用（排除已经被其他商城占用，或者与任意商城主域名、次域名重复的域名）
      apiCheckScapegoatsDomains({
        domains: list.map((item: any) => item.domain),
        sid: this.identity
      }).then((cRes)=>{
        if (cRes.repeat) {
          // {
          //   "repeat": true,
          //   "message": "备用域名已被其他商城使用：sss.com",
          //   "repeatScapegoat": [
          //       "sss.com"
          //   ],
          //   "repeatShop": []
          // }
          this.loadInstance?.close()
          this.$alert(cRes.message)

        } else {
          // 提交
          this.finalSubmit()
        }
      })
    } else {
      // 提交
      this.finalSubmit()
    }
  }

  // 最终提交
  finalSubmit () {
    clog('最终提交')
    this.form.scapegoatsSubmit = this.form.scapegoats.infos
    if (!this.identity) {
      this.handleShopAdd()
    } else {
      this.handleShopEdit()
    }
  }

  // 添加商城
  handleShopAdd() {
    apiShopAdd({...this.form}).then(() => {
      setTimeout(() => {
        this.loadInstance?.close()
        this.$router.go(-1)
      }, 500)
    }).catch(() => {
      this.loadInstance?.close()
    })
  }

  // 编辑商城
  handleShopEdit() {
    apiShopEdit({...this.form, id: this.identity}).then(() => {
      setTimeout(() => {
        this.loadInstance?.close()
        this.$router.go(-1)
      }, 500)
    }).catch(() => {
      this.loadInstance?.close()
    })
  }

  // 获取详情
  async getShopDetailFunc(): Promise<void> {
    const res: any = await apiShopDetail({
      id: this.identity as number
    })
    Object.keys(res).map((key) => {
      this.$set(this.form, key, res[key])
    })
  }

  // 获取套餐列表
  async getSetMealLists(): Promise<void> {
    const {lists} = await apiSetMealLists({status: 1})
    this.setMealData = lists
  }

  /** E Methods **/

  /** S Life Cycle **/
  created() {
    const query: any = this.$route.query

    // 编辑模式：初始化数据
    this.identity = +query.id
    if (+query.id) {
      this.getShopDetailFunc()
    } else {
      // 新增模式
      this.form.expires_time = timeFormat((new Date()).getTime() + (1*365*24*60*60*1000), 'yyyy-mm-dd')
    }

    this.onSubmit = debounce(this.onSubmit, 500)
    this.getSetMealLists()
  }

  /** E Life Cycle **/
}
